import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/images/logo.webp';
import ROUTE_PATH from '../../router/routePath';
import SidebarLink from './SidebarLink';
import { getAuthor } from '../../store/user/userActions';
import { authorSelector } from '../../store/user/userSelector';

import './style.scss';
import {USER_ROLE} from "../../helper/constant";

const LEFT_MENU_DATA = [
  {
    routePath: ROUTE_PATH.scheduler,
    title: 'Scheduler',
    role: ['Admin', 'Customer', 'General', 'Publisher'],
    isLink: true,
  },
  {
    title: 'Ad Storm',
    role: ['Admin', 'General', 'Publisher', 'Advertiser'],
    items: [
      {
        routePath: ROUTE_PATH.publisher,
        title: 'Publisher',
        role: ['Admin', 'General', 'Publisher'],
        isLink: true,
      },
      {
        routePath: ROUTE_PATH.advertiser,
        title: 'Advertiser',
        role: ['Admin', 'General', 'Advertiser'],
        isLink: true,
      },
    ]
  },
  {
    routePath: ROUTE_PATH.provision,
    title: 'Provision',
    role: ['Admin', 'General', 'Publisher'],
    isLink: true,
  },
];

const RIGHT_MENU_DATA = [
  {
    title: 'CMS',
    role: ['Admin', 'Customer', 'General', 'Publisher'],
    items: [
      {
        routePath: ROUTE_PATH.vod,
        title: 'VOD',
        role: ['Admin', 'Customer', 'General'],
        isLink: false,
      },
      {
        routePath: ROUTE_PATH.transcoding,
        title: 'Transcoding',
        role: ['Admin', 'Customer', 'General', 'Publisher'],
        isLink: false,
      },
      {
        routePath: ROUTE_PATH.fileManager,
        title: 'File Manager',
        role: ['Admin', 'Customer', 'General', 'Publisher'],
        isLink: false,
      },
      {
        routePath: ROUTE_PATH.analytics,
        title: 'Analytics',
        role: ['Admin', 'Customer', 'General'],
        isLink: false,
      },
    ]
  },
  {
    routePath: ROUTE_PATH.bucket,
    title: 'Buckets',
    role: ['Admin', 'General'],
    isLink: false,
  },
  {
    routePath: ROUTE_PATH.organization,
    title: 'Organization',
    role: ['Admin', 'General'],
    isLink: true,
  },
  {
    routePath: ROUTE_PATH.users,
    title: 'Users',
    role: ['Admin'],
    isLink: false,
  },
];

const SideBar = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const author = useSelector(authorSelector);

  const [leftMenuData, setLeftMenuData] = useState(LEFT_MENU_DATA);
  const [rightMenuData, setRightMenuData] = useState(RIGHT_MENU_DATA);

  useEffect(() => {
    const authenticate = async () => {
      const userToken = localStorage.getItem("token");
      if (!userToken) {
        window.location.assign('/onboard');
      } else {
        dispatch(getAuthor());
      }
    }

    authenticate()
  }, [navigate, dispatch]);

  useEffect(() => {
    let leftMenu = [...LEFT_MENU_DATA];
    let rightMenu = [...RIGHT_MENU_DATA]
    const userRole = author?.role?.[0]?.name;
    if ( userRole !== USER_ROLE.admin) {
      if (!author?.role || author.role.length === 0) {
        leftMenu = []
        rightMenu = []
      } else {
        leftMenu = leftMenu.filter(item => {
          return undefined !== author.role.find(role => item.role.includes(role.name))
        }).map(menu => {
          if (menu?.items) {
            return {
              ...menu,
              items: menu.items.filter(item => {
                return undefined !== author.role.find(role => item.role.includes(role.name))
              })
            }
          }
          return menu
        })

        rightMenu = rightMenu.filter(item => {
          return undefined !== author.role.find(role => item.role.includes(role.name))
        }).map(menu => {
          if (menu?.items) {
            return {
              ...menu,
              items: menu.items.filter(item => {
                return undefined !== author.role.find(role => item.role.includes(role.name))
              })
            }
          }
          return menu
        })
      }
    }

    setLeftMenuData(leftMenu);
    setRightMenuData(rightMenu);
  }, [author]);

  const handleClickLogout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_info");
    window.location.assign('/onboard');
  }, [navigate]);

  return (
    <div className="showfer-admin">
      <div className={'header-background'} />
      <div className="header">
        <Link className="header-home-link" to={ROUTE_PATH.home}>
          <img src={logo} alt="showfer" className="header-logo" />
        </Link>
        <div className="header-menu">
          <div className="header-menu-left">

            {leftMenuData.map((sideBar) => {
              const { routePath, title, items, isLink } = sideBar;
              if (isLink) {
                return <a className={'header-menu-item'} href={ routePath }>{ title }</a>
              }
              return (
                  <SidebarLink
                      to={ routePath }
                      title={ title }
                      key={ routePath }
                      items={ items }
                  />
              );
            })}
          </div>
          <div className="header-menu-right">
            {rightMenuData.map((sideBar) => {
              const { routePath, title, items, isLink } = sideBar;
              if (isLink) {
                return <a className={'header-menu-item'} href={ routePath }>{ title }</a>
              }
              return (
                  <SidebarLink
                      to={ routePath }
                      title={ title }
                      key={ routePath }
                      items={ items }
                  />
              );
            })}

            <a href="#" className="header-menu-item" onClick={ handleClickLogout }>Log out</a>
          </div>
        </div>
      </div>
      <div className="body">{ children }</div>
    </div>
  );
};

export default SideBar;
