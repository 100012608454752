import React from 'react';
import { NavLink } from "react-router-dom";
import {Button, Dropdown, Menu} from "antd";
import {CopyOutlined, DownOutlined} from "@ant-design/icons";

const SidebarLink = ({to, title, items}) => {
    if (items?.length > 0) {
        const currentUrl = window.location.pathname;
        let active = '';
        for (let i = 0; i < items.length; i++) {
            if(items[i]?.routePath === currentUrl) {
                active = ' active';
            }
        }
        return (
            <Dropdown trigger={['hover']} overlay={ menuHls(items) } >
                <a className={`header-menu-item dropdown ${active}`} >
                    <span className={"title"}>{title}</span><DownOutlined />
                </a>
            </Dropdown>
        );
    }

    return (
        <NavLink to={to} className={({isActive}) => isActive ? "header-menu-item active" : "header-menu-item"}>
            <span>{title}</span>
        </NavLink>
    );
};

const menuHls = (menuItems) => {
  const rows = [];
  for (let i = 0; i < menuItems.length; i++) {
      let isActive = false;
      if(menuItems[i].routePath === window.location.pathname) {
          isActive = true;
      }
    rows.push(

        <Menu.Item key={menuItems[i].title} className={"dropdown-menu " + (isActive ? "active" : '')}>
            {
                menuItems[i].isLink &&
                <a className={'header-menu-item'} href={ menuItems[i].routePath }>{ menuItems[i].title }</a>
            }
            {
                !menuItems[i].isLink &&
                <NavLink to={menuItems[i].routePath}>
                    <span>{menuItems[i].title}</span>
                </NavLink>
            }
        </Menu.Item>
    );
  }

  return (
      <Menu>{ rows }</Menu>
  );
}

export default SidebarLink;
