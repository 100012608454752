import React from 'react';
import {useLocation} from 'react-router-dom';
import SideBar from '../components/SideBar/SideBar';
import Home from '../pages/Home/Home';
import Login from '../pages/Login/Login';
import ROUTE_PATH from './routePath';

const UploadFile = React.lazy(() => import('../pages/UploadFile/UploadFile'));
const Bucket = React.lazy(() => import('../pages/Bucket/Bucket'));
const BucketEditor = React.lazy(() => import('../pages/BucketEditor/BucketEditor'));
const Users = React.lazy(() => import('../pages/Users'));
const UserEditor = React.lazy(() => import('../pages/UserEditor'));
const Analytics = React.lazy(() => import('../pages/Analytics/Analytics'));
const Hls = React.lazy(() => import('../pages/Hls/Hls'));
const Mrss = React.lazy(() => import('../pages/Mrss/Mrss'));
const MrssEditor = React.lazy(() => import('../pages/MrssEditor/MrssEditor'));
const LiveStream = React.lazy(() => import('../pages/LiveStream/LiveStream'));
const HlsEditor = React.lazy(() => import('../pages/HlsEditor/HlsEditor'));
const NotFound = React.lazy(() => import('../pages/NotFound/NotFound'));

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  localStorage.setItem("previous", location.pathname);

  const token = localStorage.getItem('token');
  return token ? children : window.location.assign(ROUTE_PATH.login);
};

const ROUTES = [
  {
    path: ROUTE_PATH.home,
    component: Home,
    guard: PrivateRoute,
    layout: SideBar,
  },
  {
    path: ROUTE_PATH.login,
    component: Login,
  },
  {
    path: ROUTE_PATH.transcoding,
    component: LiveStream,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Publisher']
  },
  {
    path: ROUTE_PATH.analytics,
    component: Analytics,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General']
  },
  {
    path: ROUTE_PATH.users,
    component: Users,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin']
  },
  {
    path: ROUTE_PATH.userCreator,
    component: UserEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin']
  },
  {
    path: ROUTE_PATH.userEditor,
    component: UserEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin']
  },
  {
    path: ROUTE_PATH.hls,
    component: Hls,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General'],
  },
  {
    path: ROUTE_PATH.vod,
    component: Mrss,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General']
  },
  {
    path: ROUTE_PATH.vodCreator,
    component: MrssEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General']
  },
  {
    path: ROUTE_PATH.vodEditor,
    guard: PrivateRoute,
    component: MrssEditor,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General']
  },
  {
    path: ROUTE_PATH.hlsEditor,
    component: HlsEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General']
  },
  {
    path: ROUTE_PATH.notFound,
    component: NotFound,
    guard: PrivateRoute,
    layout: SideBar,
  },
  {
    path: ROUTE_PATH.bucket,
    component: Bucket,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'General']
  },
  {
    path: ROUTE_PATH.bucketCreate,
    component: BucketEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'General']
  },
  {
    path: ROUTE_PATH.bucketEdit,
    component: BucketEditor,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'General']
  },
  {
    path: ROUTE_PATH.fileManager,
    component: UploadFile,
    guard: PrivateRoute,
    layout: SideBar,
    roles: ['Admin', 'Customer', 'General', 'Publisher']
  }
];

export default ROUTES;
