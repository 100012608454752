import axios from 'axios';

const axiosApi = axios.create({
  // baseUrl: 'http://34.41.107.250/api/v2',
  baseURL: 'https://api.showfer.com/api',
  // baseURL: 'http://localhost:8000/api',
  // baseURL: 'http://34.68.19.104/api/v2',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    accept: 'application/json',
  },
});

axiosApi.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

axiosApi.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const status = error.response?.status || 500;

  console.log(status === 401);

  switch (status) {
    case 401: {
      localStorage.removeItem("token");
      localStorage.removeItem("user_info");

      window.location.href = '/';

      return Promise.reject(error);
    }

    default: {
      return Promise.reject(error);
    }
  }
});

export default axiosApi;
